import { createSlice } from "@reduxjs/toolkit";
import { authorizeAgent, getAuthorizedAgentsList } from "./adminActions";

const initialState = {
  agentAuthorizationList: {
    authorizedEmails: [] as any[],
    isError: false,
    message: "",
    isLoading: false,
  },
  authorizeAgent: {
    isError: false,
    isSuccess: false,
    message: "",
    isLoading: false,
  },
};

export const hedgeMAPsSlice = createSlice({
  name: "admin",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    // Get HedgeMAP Listing`
    builder
      .addCase(getAuthorizedAgentsList.fulfilled, (state, action) => {
        state.agentAuthorizationList.authorizedEmails =
          action.payload.authorized_emails;
        state.agentAuthorizationList.isError = false;
        state.agentAuthorizationList.message = "";
        state.agentAuthorizationList.isLoading = false;
      })
      .addCase(getAuthorizedAgentsList.pending, (state, action) => {
        state.agentAuthorizationList.isError = false;
        state.agentAuthorizationList.message = "Loading...";
        state.agentAuthorizationList.isLoading = true;
      })
      .addCase(getAuthorizedAgentsList.rejected, (state, action) => {
        state.agentAuthorizationList.isError = true;
        state.agentAuthorizationList.message = action.payload as string;
        state.agentAuthorizationList.isLoading = false;
      });

    // Download HedgeMAP
    builder
      .addCase(authorizeAgent.fulfilled, (state, action) => {
        state.authorizeAgent.isError = false;
        state.authorizeAgent.isSuccess = true;
        state.authorizeAgent.message = `Successfully authorized ${action.payload.email}!`;
        state.authorizeAgent.isLoading = false;
      })
      .addCase(authorizeAgent.pending, (state, action) => {
        state.authorizeAgent.isError = false;
        state.authorizeAgent.message = "Loading...";
        state.authorizeAgent.isLoading = true;
      })
      .addCase(authorizeAgent.rejected, (state, action) => {
        state.authorizeAgent.isError = true;
        state.authorizeAgent.message = action.payload as string;
        state.authorizeAgent.isLoading = false;
      });
  },
});

// export const {} = hedgeMAPsSlice.actions;

export default hedgeMAPsSlice.reducer;
