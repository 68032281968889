import React, { useEffect } from "react";
import DecisionToolContainer from "../../DecisionToolContainer";
import LGMPolicySelector from "../components/LGMPolicySelector";
import LGMMarketingPlan from "../components/LGMMarketingPlan";
import LGMResultTableContainer from "../components/ResultTableContainer";

import "./LGMDecisionTool.scss";
import LGMTypeSelector from "../components/LGMTypeSelector";
import { useAppDispatch } from "../../../app/redux/hooks";
import { resetLGMQuoteResults } from "../features/LGMSlice";

function LGMDecisionTool() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // On page load set first tab to active
    //clear quote results on page load as running classvscomponent/strip, navigating to another page then back would try to load thoe reuslts on single
    dispatch(resetLGMQuoteResults());
  }, [dispatch]);
  return (
    <DecisionToolContainer title={"Livestock Gross Margin Decision Tool"}>
      <div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMPolicySelector />
        </div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMTypeSelector />
        </div>
        <div className="col my-1" style={{ minWidth: 300 }}>
          <LGMMarketingPlan />
        </div>
        <LGMResultTableContainer />
      </div>
    </DecisionToolContainer>
  );
}

export default LGMDecisionTool;
