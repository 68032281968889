import { sendAdminRequest } from "../../app/utils/sendRequest";

const getAuthorizedAgentsList = async () => {
  const apifunc = "admin/list-authorized-agents";
  const args = {};

  return sendAdminRequest(apifunc, args);
};

const authorizeAgent = async (email: string) => {
  const apifunc = "admin/authorize-agent-email";
  const args = {
    email_to_authorize: email,
  };

  return sendAdminRequest(apifunc, args);
};

const hedgeMAPsService = {
  getAuthorizedAgentsList,
  authorizeAgent,
};

export default hedgeMAPsService;
