import adminService from "./adminService";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAuthorizedAgentsList = createAsyncThunk(
  "drp/getAuthorizedAgentsList",
  async (_: void, thunkAPI) => {
    try {
      const response = await adminService.getAuthorizedAgentsList();
      const payload = await response.data;

      if (response.status === 200) {
        return payload;
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to fetch authorized agents list. Please try again later.",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to fetch authorized agents list. Please try again later.",
      );
    }
  },
);

export const authorizeAgent = createAsyncThunk(
  "drp/authorizeAgent",
  async (data: any, thunkAPI) => {
    try {
      const response = await adminService.authorizeAgent(data);
      const payload = await response.data;

      if (response.status === 200) {
        try {
          thunkAPI.dispatch(getAuthorizedAgentsList());
        } catch (error) {
          console.error(error);
        } finally {
          return payload;
        }
      } else {
        return thunkAPI.rejectWithValue(
          "Unable to authorize agent. Please try again later",
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Unable to authorize agent. Please try again later",
      );
    }
  },
);
