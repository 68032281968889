import React, { useState } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./Authentication/pages/Login/Login";
import Logout from "./Authentication/pages/Logout/Logout";
import PasswordResetRequest from "./Authentication/pages/PasswordReset/PasswordResetRequest";
import PasswordResetConfirm from "./Authentication/pages/PasswordReset/PasswordResetConfirm";

import SideMenu from "./SideMenu/components/SideMenu";

import DRPDecisionTool from "./DecisionTools/DRP/pages/DRPDecisionTool";
import LRPDecisionTool from "./DecisionTools/LRP/pages/LRPDecisionTool";
import LGMDecisionTool from "./DecisionTools/LGM/pages/LGMDecisionTool";

import PrivateRoutes from "./Authentication/components/PrivateRoutes";
import ProtectedRoutes from "./Authentication/components/ProtectedRoutes";

import ProducerRegistration from "./Authentication/components/Producer/ProducerRegistration";
import AgentRegistration from "./Authentication/components/Agent/AgentRegistration";
import AuthorizeProducers from "./Authentication/pages/AuthorizeProducers/AuthorizeProducers";
import ManageAccount from "./Authentication/pages/ManageAccount/ManageAccount";
import Resources from "./Resources/pages/Resources";
import ViewHedgeMAPs from "./ViewHedgeMAPs/pages/ViewHedgeMAPs";
import LoadingScreen from "./Authentication/components/LoadingScreen";
import GeneralLogin from "./Authentication/components/GeneralLogin";

import NavWrapper from "./NavWrapper";
import Admin from "./Admin/pages/Admin";
import AuthorizeAgentEmail from "./Admin/components/AuthorizeAgentEmail";
import Podcasts from "./Podcasts/pages/Podcasts";
import AgentHedgeMAPAuthorization from "./Authentication/pages/AgentHedgeMAPAuthorization/AgentHedgeMAPAuthorization";
import ProducerViewHedgeMAPs from "./ProducerViewHedgeMAPs/pages/ProducerViewHedgeMAPs";
import DeleteAccount from "./Authentication/pages/DeleteAccount/DeleteAccount";
import PrivacyPolicy from "./Resources/pages/PrivacyPolicy";
import Support from "./Resources/pages/Support";
// import AgentLinkProducerUser from "./pages/auth/AgentLinkProducerUser/AgentLinkProducerUser";

// import useAxiosInterceptor from "./utils/hooks/useAxiosInterceptor";
function Home() {
  return <div></div>;
}
function NotFound() {
  return (
    <div>
      <h1>404</h1>
      <h4>Page not found!</h4>
    </div>
  );
}

function App() {
  // useAxiosInterceptor();
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <Router>
        <SideMenu toggleSidebar={toggleSidebar} isOpen={isOpen} />
        <div className="disable-animations ag-container">
          <NavWrapper isOpen={isOpen}>
            <Routes>
              {/* Private Routes */}
              <Route element={<PrivateRoutes />}>
                {/* Put routes that require login within this Route component */}
                <Route path="/logout" element={<Logout />} />
                <Route path="/loading-screen" element={<LoadingScreen />} />
                <Route path="/manage-account" element={<ManageAccount />} />
                {/* Protected Routes */}
                <Route element={<ProtectedRoutes />}>
                  {/* Put routes that require login and compelted registration within this Route component */}
                  <Route path="/" element={<Home />} />
                  <Route path="/decisiontool" element={<DRPDecisionTool />} />
                  <Route
                    path="/lrp-decisiontool"
                    element={<LRPDecisionTool />}
                  />
                  <Route
                    path="/lgm-decisiontool"
                    element={<LGMDecisionTool />}
                  />
                  <Route path="/resources" element={<Resources />} />
                  <Route path="/view-hedgeMAPs" element={<ViewHedgeMAPs />} />
                  <Route path="/podcasts" element={<Podcasts />} />

                  <Route
                    path="/authorize-producers"
                    element={<AuthorizeProducers />}
                  />
                  <Route
                    path="/producer-view-hedgemaps"
                    element={<ProducerViewHedgeMAPs />}
                  />
                  <Route path="/admin" element={<Admin />} />
                  <Route
                    path="/admin/authorize-agent-email"
                    element={<AuthorizeAgentEmail />}
                  />
                  <Route
                    path="/admin/agent-authorize-producer-hedgemap"
                    element={<AgentHedgeMAPAuthorization />}
                  />

                  {/* <Route
                    path="/admin/agent-link-user-producer"
                    element={<AgentLinkProducerUser />}
                  /> */}
                </Route>
              </Route>

              {/* Public Routes */}

              <Route path="/login" element={<Login />} />
              <Route path="/login-general" element={<GeneralLogin />} />
              {/* <Route path="/login-producer" element={<ProducerLogin />} /> */}

              <Route
                path="/register-producer"
                element={<ProducerRegistration />}
              />
              <Route path="/register-agent" element={<AgentRegistration />} />

              <Route
                path="/reset-password"
                element={<PasswordResetRequest />}
              />

              <Route
                path="/reset-password-finalize"
                element={<PasswordResetConfirm />}
              />

              <Route
                path="/request-account-deletion"
                element={<DeleteAccount />}
              />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </NavWrapper>
        </div>
      </Router>
    </>
  );
}

export default App;
