import React from "react";
import { useAppSelector } from "../../../../app/redux/hooks";

import IndemnitySliderButton from "../../../DRP/components/ResultTableIndemnities/IndemnitySliderButton";

const LGMResultTableIndemnityDefault = (props) => {
  const { lgm_quote_results } = useAppSelector(
    (state) => state.lgm.lgmQuoteResults,
  );
  const { indemnityBasePrices } = useAppSelector((state) => state.lgm);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title mb-3">Indemnity Calculations:</h5>
        <div className="card-text mb-3">
          Use the sliders below to see how your endorsement will react under
          various market conditions.
        </div>
        <div className="form-group">
          <table>
            <thead></thead>
            <tbody>
              <tr>
                <td>
                  <p>Index Price: </p>
                </td>
                <td>
                  <p
                    className="text-center fw-bold price-clprop"
                    style={{ paddingLeft: "42px" }}
                  >
                    {props.indexValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    {/* {props.indexValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} */}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style={{ display: "block" }}
            className="table table-sm table-borderless"
          >
            {lgm_quote_results[0]["quote_params"]["commodity_type"]["value"] ===
              "CATTLE" && (
              <tbody>
                <tr>
                  <th scope="row">Cattle Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Cattle"}
                      value={-props.slider_consts.cattle.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.cattle.min}
                      max={props.slider_consts.cattle.max}
                      value={props.sliderValues.cattleSlider}
                      name="Cattle"
                      onChange={(e) => {
                        props.sliderMethods.updateFromCattleSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.cattle.inc}
                    />
                    <IndemnitySliderButton
                      name={"Cattle"}
                      value={props.slider_consts.cattle.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div
                      className="d-inline-block fw-bold"
                      key={
                        props.projectedPrices.projectedCattlePrice &&
                        props.projectedPrices.projectedCattlePrice
                      }
                    >
                      {props.projectedPrices.projectedCattlePrice &&
                        props.projectedPrices.projectedCattlePrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal" key={indemnityBasePrices.cattle}>
                      (
                      {indemnityBasePrices.cattle &&
                        indemnityBasePrices.cattle.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Feeder Cattle:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Feeder Cattle"}
                      value={-props.slider_consts.feeder_cattle.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.feeder_cattle.min}
                      max={props.slider_consts.feeder_cattle.max}
                      value={props.sliderValues.feederCattleSlider}
                      name="Feeder Cattle"
                      onChange={(e) => {
                        props.sliderMethods.updateFromFeederCattleSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.feeder_cattle.inc}
                    />
                    <IndemnitySliderButton
                      name={"Feeder Cattle"}
                      value={props.slider_consts.feeder_cattle.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedFeederCattlePrice &&
                        props.projectedPrices.projectedFeederCattlePrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.feeder_cattle &&
                        indemnityBasePrices.feeder_cattle.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      )
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Corn Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Corn"}
                      value={-props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.corn.min}
                      max={props.slider_consts.corn.max}
                      name="Corn"
                      value={props.sliderValues.cornSlider}
                      onChange={(e) =>
                        props.sliderMethods.updateFromCornSlider(
                          Number(e.target.value),
                        )
                      }
                      step={props.slider_consts.corn.inc}
                    />

                    <IndemnitySliderButton
                      name={"Corn"}
                      value={props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedCornPrice &&
                        props.projectedPrices.projectedCornPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.corn &&
                        indemnityBasePrices.corn.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
              </tbody>
            )}

            {lgm_quote_results[0]["quote_params"]["commodity_type"]["value"] ===
              "SWINE" && (
              <tbody>
                <tr>
                  <th scope="row">Swine Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Swine"}
                      value={-props.slider_consts.swine.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.swine.min}
                      max={props.slider_consts.swine.max}
                      value={props.sliderValues.swineSlider}
                      name="Swine"
                      onChange={(e) => {
                        props.sliderMethods.updateFromSwineSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.swine.inc}
                    />
                    <IndemnitySliderButton
                      name={"Swine"}
                      value={props.slider_consts.swine.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedSwinePrice &&
                        props.projectedPrices.projectedSwinePrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.swine &&
                        indemnityBasePrices.swine.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Corn Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Corn"}
                      value={-props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.corn.min}
                      max={props.slider_consts.corn.max}
                      value={props.sliderValues.cornSlider}
                      name="Corn"
                      onChange={(e) => {
                        props.sliderMethods.updateFromCornSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.corn.inc}
                    />
                    <IndemnitySliderButton
                      name={"Corn"}
                      value={props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedCornPrice &&
                        props.projectedPrices.projectedCornPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.corn &&
                        indemnityBasePrices.corn.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Soybean Meal Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Soybean"}
                      value={-props.slider_consts.soybean.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.soybean.min}
                      max={props.slider_consts.soybean.max}
                      name="Soybean"
                      value={props.sliderValues.soybeanSlider}
                      onChange={(e) =>
                        props.sliderMethods.updateFromSoybeanSlider(
                          Number(e.target.value),
                        )
                      }
                      step={props.slider_consts.soybean.inc}
                    />

                    <IndemnitySliderButton
                      name={"Soybean"}
                      value={props.slider_consts.soybean.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedSoybeanPrice &&
                        props.projectedPrices.projectedSoybeanPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.soybean &&
                        indemnityBasePrices.soybean.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
              </tbody>
            )}

            {lgm_quote_results[0]["quote_params"]["commodity_type"]["value"] ===
              "DAIRY_CATTLE" && (
              <tbody>
                <tr>
                  <th scope="row">Milk Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Milk"}
                      value={-props.slider_consts.milk.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.milk.min}
                      max={props.slider_consts.milk.max}
                      value={props.sliderValues.milkSlider}
                      name="Milk"
                      onChange={(e) => {
                        props.sliderMethods.updateFromMilkSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.milk.inc}
                    />
                    <IndemnitySliderButton
                      name={"Milk"}
                      value={props.slider_consts.milk.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedMilkPrice &&
                        props.projectedPrices.projectedMilkPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.milk &&
                        indemnityBasePrices.milk.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Corn Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Corn"}
                      value={-props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.corn.min}
                      max={props.slider_consts.corn.max}
                      value={props.sliderValues.cornSlider}
                      name="Corn"
                      onChange={(e) => {
                        props.sliderMethods.updateFromCornSlider(
                          Number(e.target.value),
                        );
                      }}
                      step={props.slider_consts.corn.inc}
                    />
                    <IndemnitySliderButton
                      name={"Corn"}
                      value={props.slider_consts.corn.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedCornPrice &&
                        props.projectedPrices.projectedCornPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.corn &&
                        indemnityBasePrices.corn.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Soybean Meal Price:</th>
                  <td className="slider-cell">
                    <IndemnitySliderButton
                      name={"Soybean"}
                      value={-props.slider_consts.soybean.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                    <input
                      type="range"
                      min={props.slider_consts.soybean.min}
                      max={props.slider_consts.soybean.max}
                      name="Soybean"
                      value={props.sliderValues.soybeanSlider}
                      onChange={(e) =>
                        props.sliderMethods.updateFromSoybeanSlider(
                          Number(e.target.value),
                        )
                      }
                      step={props.slider_consts.soybean.inc}
                    />

                    <IndemnitySliderButton
                      name={"Soybean"}
                      value={props.slider_consts.soybean.inc}
                      onClick={props.sliderMethods.incrementSlider}
                      onMouseDown={props.sliderMethods.startIncrementSlider}
                      onMouseUp={props.sliderMethods.stopSliderIncrement}
                    />
                  </td>
                  <td>
                    <div className="d-inline-block fw-bold">
                      {props.projectedPrices.projectedSoybeanPrice &&
                        props.projectedPrices.projectedSoybeanPrice.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                    </div>
                  </td>
                  <td>
                    <div className="fw-normal">
                      (
                      {indemnityBasePrices.soybean &&
                        indemnityBasePrices.soybean.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      )
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <button
            className="btn btn-secondary btn-sm"
            style={{ marginLeft: "3px" }}
            onClick={props.sliderMethods.resetAllSliders}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default LGMResultTableIndemnityDefault;
