import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/redux/hooks";
import { setLGMCattleMarketingPlan } from "../../features/LGMSlice";
import { LGMCommodityType } from "@agdeveloper/domain";

const LGMCattleMarketingPlan = () => {
  const dispatch = useAppDispatch();

  const { type, cattle_marketing_plan } = useAppSelector(
    (state) => state.lgm.input,
  );
  const { target_months } = useAppSelector((state) => state.lgm);

  const { isError, isLoading, expectedGrossMargins } = useAppSelector(
    (state) => state.lgm.expectedGrossMarginsLGM,
  );
  const [marketingPlan, setMarketingPlan] = useState(cattle_marketing_plan);

  const updateMarketingPlan = (month, marketed) => {
    setMarketingPlan({
      ...cattle_marketing_plan,
      [month]: marketed,
    });
    // setTypeName(type_name);
  };

  useEffect(() => {
    dispatch(setLGMCattleMarketingPlan(marketingPlan));
  }, [marketingPlan, dispatch]);

  return (
    <div className="card w-100">
      <div className="card-body">
        <h5 className="card-title">Insured Cattle</h5>
        <p className="card-text">
          Enter the number of cattle insured each month
        </p>
        <div className="table-responsive">
          <table className="table table-fit">
            <thead>
              <tr>
                <th></th>
                {target_months.map((month) => (
                  <th scope="col" key={month}>
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "6em" }}>
                  Cattle Marketed:
                </th>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((idx) => (
                  <td key={idx}>
                    <input
                      value={cattle_marketing_plan[target_months[idx]]}
                      onChange={(e) =>
                        updateMarketingPlan(target_months[idx], e.target.value)
                      }
                      type="number"
                      style={{ width: "6em" }}
                    />
                  </td>
                ))}
              </tr>
              {expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value] &&
                expectedGrossMargins?.[LGMCommodityType.CATTLE]?.[type?.value]
                  .length === 10 &&
                !isError && (
                  <tr>
                    <th>
                      Expected Gross Margins: <br />
                      (Per Head)
                    </th>
                    {isLoading ? (
                      <td style={{ textAlign: "center" }}>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <br />
                        <span>Loading...</span>
                      </td>
                    ) : (
                      <>
                        {expectedGrossMargins?.[LGMCommodityType.CATTLE][
                          type?.value
                        ].map((item, index) => {
                          return (
                            <td key={index}>
                              <div style={{ width: "6em", textAlign: "left" }}>
                                {cattle_marketing_plan[target_months[index]] > 0
                                  ? `$${(
                                      item *
                                      cattle_marketing_plan[
                                        target_months[index]
                                      ]
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}`
                                  : "-"}
                              </div>
                              <div style={{ width: "6em", textAlign: "left" }}>
                                {`($${item.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })})`}
                              </div>
                            </td>
                          );
                        })}
                      </>
                    )}
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default LGMCattleMarketingPlan;
